import React, { useCallback, useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react'
import { Grid, Typography, Tooltip } from '@material-ui/core'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import { Button } from '@refera/ui-web'

import { phoneNumberMask } from '_utils/helpers'
import CategoryIndicator from '_components/category-indicator'
import Chip from '_components/chip'
import Table from '_/components/table'
import {
  getProviders,
  setTablePage,
  getProvidersNextPage,
  getProvidersFiltered,
  setFilter,
} from '_modules/provider/actions'
import {
  providersSelector,
  getProvidersLoadingSelector,
  providersResultSelector,
} from '_modules/provider/selectors'
import {
  REGISTER_STATUS,
  REGISTER_STATUS_LABEL,
  ROUTES,
  STAGE_TYPES_COMPANY,
  STAGE_TYPES_COMPANY_LABEL,
} from '_utils/constants'
import { getCitiesOptions } from '_modules/utils/selectors'
import { getCities } from '_modules/utils/actions'
import { categoryOptionSelector } from '_modules/categories/selectors'
import { getCategories, getCategoriesFiltered } from '_modules/categories/actions'
import { usePrevious } from '_hooks/use-previous'

import { PROVIDER_FIELDS } from '../provider/constants'

import useStyles from './styles'
import HeaderTitle from '_/components/header-title'
import { PERMISSIONS, PERMISSIONS_ACTIONS } from '_/utils/constants/permissions'
import useRolePermission from '_/hooks/use-role-permission'

const COLUMNS = [
  { id: PROVIDER_FIELDS.NAME, label: 'Nome', minWidth: 150 },
  { id: PROVIDER_FIELDS.PERSON_RESPONSIBLE_NAME, label: 'Responsável', minWidth: 150 },
  { id: PROVIDER_FIELDS.PHONE, label: 'Telefone', minWidth: 50 },
  {
    id: PROVIDER_FIELDS.MAIN_SERVICE_NAMES,
    label: 'Categoria',
    placeholder: 'da categoria',
    minWidth: 100,
    maxWidth: 120,
    filterOptions: [],
    search: true,
    filterName: 'mainServices',
  },
  { id: PROVIDER_FIELDS.CONTACT_EMAIL, label: 'Email' },
  {
    id: PROVIDER_FIELDS.ONBOARDING_STAGE,
    label: 'Etapa',
    filterOptions: [
      {
        label: STAGE_TYPES_COMPANY_LABEL[STAGE_TYPES_COMPANY.WAITING_LIST],
        value: STAGE_TYPES_COMPANY.WAITING_LIST,
      },
      {
        label: STAGE_TYPES_COMPANY_LABEL[STAGE_TYPES_COMPANY.FIRST_REQUEST],
        value: STAGE_TYPES_COMPANY.FIRST_REQUEST,
      },
      {
        label: STAGE_TYPES_COMPANY_LABEL[STAGE_TYPES_COMPANY.FIRST_BUDGET],
        value: STAGE_TYPES_COMPANY.FIRST_BUDGET,
      },
      {
        label: STAGE_TYPES_COMPANY_LABEL[STAGE_TYPES_COMPANY.BUDGET_CORRECTION],
        value: STAGE_TYPES_COMPANY.BUDGET_CORRECTION,
      },
      {
        label: STAGE_TYPES_COMPANY_LABEL[STAGE_TYPES_COMPANY.FIRST_APPROVAL],
        value: STAGE_TYPES_COMPANY.FIRST_APPROVAL,
      },
      {
        label: STAGE_TYPES_COMPANY_LABEL[STAGE_TYPES_COMPANY.FIRST_EXECUTION],
        value: STAGE_TYPES_COMPANY.FIRST_EXECUTION,
      },
      {
        label: STAGE_TYPES_COMPANY_LABEL[STAGE_TYPES_COMPANY.BANK_DATA],
        value: STAGE_TYPES_COMPANY.BANK_DATA,
      },
      {
        label: STAGE_TYPES_COMPANY_LABEL[STAGE_TYPES_COMPANY.ONBOARDING_FINISHED],
        value: STAGE_TYPES_COMPANY.ONBOARDING_FINISHED,
      },
    ],
    search: true,
    filterName: 'stages',
  },
  {
    id: PROVIDER_FIELDS.CITY,
    label: 'Cidade',
    filterOptions: [],
    search: true,
    filterName: 'cities',
    placeholder: 'da cidade',
  },
  {
    id: PROVIDER_FIELDS.REGISTRATION_STATUS,
    label: 'Status',
    filterOptions: [
      { label: REGISTER_STATUS_LABEL[REGISTER_STATUS.COMPLETE], value: REGISTER_STATUS.COMPLETE },
      {
        label: REGISTER_STATUS_LABEL[REGISTER_STATUS.INCOMPLETE],
        value: REGISTER_STATUS.INCOMPLETE,
      },
    ],
    search: false,
    filterName: PROVIDER_FIELDS.REGISTRATION_STATUS,
  },
]

const ManageProviders = () => {
  const styles = useStyles()

  const dispatch = useDispatch()

  const { count, page, size, filter, loadedPages } = useSelector(providersSelector)
  const results = useSelector(providersResultSelector)
  const providersIsLoading = useSelector(getProvidersLoadingSelector)
  const wasLoading = usePrevious(providersIsLoading)
  const cityOptions = useSelector(getCitiesOptions)
  const serviceOptions = useSelector(categoryOptionSelector)
  const refs = useRef({})
  const [ellipsisCell, setEllipsisCell] = useState([])
  const { checkIfUserDoesNotPermission } = useRolePermission()

  const getColumns = useMemo(
    () =>
      COLUMNS.map(item => {
        if (item.id === PROVIDER_FIELDS.CITY) {
          const column = { ...item, filterOptions: [...cityOptions] }
          return column
        }
        if (item.id === PROVIDER_FIELDS.MAIN_SERVICE_NAMES) {
          const column = { ...item, filterOptions: [...serviceOptions] }
          return column
        }
        return item
      }),
    [cityOptions, serviceOptions]
  )

  const handleService = useCallback(() => {
    dispatch(getCategories)
  }, [dispatch])

  const handleCity = useCallback(() => {
    if (!cityOptions.length) {
      dispatch(getCities()).then(() => {
        handleService()
      })
    }
  }, [cityOptions.length, dispatch])

  useEffect(() => {
    dispatch(getProviders()).then(() => {
      handleCity()
    })
  }, [dispatch])

  const handleChangePage = useCallback(
    next => {
      if (!loadedPages.includes(next + 1)) {
        dispatch(getProvidersNextPage({ page: next + 1 }))
      }
      dispatch(setTablePage({ page: next }))
    },
    [dispatch, loadedPages]
  )

  const handleRowsPerPage = useCallback(
    pageSize => {
      dispatch(getProviders({ pageSize }))
      dispatch(setTablePage({ page: 0 }))
    },
    [dispatch]
  )

  const handleApplyFilter = useCallback(() => {
    dispatch(getProvidersFiltered())
  }, [dispatch])

  const handleFilterOption = useCallback(
    event => {
      const { name, value } = event.target
      dispatch(setFilter({ name, value }))

      if (!value) {
        dispatch(getProviders())
      }
    },
    [dispatch]
  )
  const handleSearchFilter = useCallback(
    name => event => {
      const { value } = event.target
      if (name === PROVIDER_FIELDS.CITY) {
        if (value) {
          dispatch(getCities(value))
          return
        }
        dispatch(getCities())
        return
      }
      if (name === PROVIDER_FIELDS.MAIN_SERVICE_NAMES) {
        if (value) {
          dispatch(getCategoriesFiltered(value))
          return
        }
        dispatch(getCities())
      }
    },
    [dispatch]
  )
  const handleTableRowClick = useCallback(event => {
    if (
      checkIfUserDoesNotPermission(PERMISSIONS.TRADESMAN_REGISTRY, [
        PERMISSIONS_ACTIONS.READ,
        PERMISSIONS_ACTIONS.EDIT,
        PERMISSIONS_ACTIONS.ADD,
      ])
    ) {
      return null
    }

    return navigate(`${ROUTES.PROVIDER}/${event.currentTarget.id}`)
  }, [])

  const checkEmailFieldEllipsis = useCallback(() => {
    const ellipsisCellState = Object.keys(refs.current).map(item => ({
      id: item,
      isEllipsis: refs.current[item]?.offsetWidth < refs.current[item]?.scrollWidth,
    }))

    setEllipsisCell(ellipsisCellState)
  }, [refs])

  const handleRefs = useCallback(
    id => node => {
      refs.current = { ...refs.current, [id]: node }
    },
    [refs]
  )

  useLayoutEffect(() => {
    if (Object.values(refs.current).length > 0 && !providersIsLoading && wasLoading) {
      checkEmailFieldEllipsis()
    }
    window.addEventListener('resize', checkEmailFieldEllipsis)

    return () => window.removeEventListener('resize', checkEmailFieldEllipsis)
  }, [checkEmailFieldEllipsis, providersIsLoading, wasLoading])

  const renderTableCell = useCallback(
    (value, key) => {
      switch (key) {
        case PROVIDER_FIELDS.NAME:
          return (
            <Typography className={styles.text} color="primary">
              {value[key]}
            </Typography>
          )
        case PROVIDER_FIELDS.ONBOARDING_STAGE: {
          if (!value[key]) {
            return value[key]
          }
          return <Chip label={STAGE_TYPES_COMPANY_LABEL[value[key]]} />
        }
        case PROVIDER_FIELDS.MAIN_SERVICE_NAMES: {
          const categories = value[key]?.split(',')
          if (!value[key]) {
            return value[key]
          }
          if (categories?.length > 1)
            return (
              <Grid container alignItems="center">
                <Chip label={categories[0]} className={styles.category} />
                <CategoryIndicator
                  tooltipText={categories.join(', ').replace(/, ((?:.(?!, ))+)$/, ' e $1')}
                  label={`+${categories.length - 1}`}
                />
              </Grid>
            )
          return <Chip label={categories} />
        }
        case PROVIDER_FIELDS.REGISTRATION_STATUS: {
          const isComplete = value[key] === REGISTER_STATUS.COMPLETE
          return (
            <Chip
              label={REGISTER_STATUS_LABEL[value[key]]}
              className={classnames(styles.chip, { [styles.incomplete]: !isComplete })}
            />
          )
        }
        case PROVIDER_FIELDS.CONTACT_EMAIL:
          return (
            <Tooltip
              title={value[key]}
              disableHoverListener={
                !ellipsisCell.find(item => Number(item.id) === Number(value.id))?.isEllipsis
              }
            >
              <Typography className={styles.text} ref={handleRefs(value.id)}>
                {value[key]}
              </Typography>
            </Tooltip>
          )

        case PROVIDER_FIELDS.PHONE: {
          return (
            <Typography className={styles.text}>
              {phoneNumberMask(value[key]).replace('+55', '')}
            </Typography>
          )
        }
        default:
          return <Typography className={styles.text}>{value[key]}</Typography>
      }
    },
    [ellipsisCell, handleRefs, styles.category, styles.chip, styles.incomplete, styles.text]
  )
  return (
    <Grid container className={styles.container}>
      <HeaderTitle title="Gestão de Prestadores">
        <div className={styles.actionButtons}>
          <Button onClick={() => navigate(ROUTES.LEAD_PROVIDERS)}>Importar prestadores</Button>
        </div>
      </HeaderTitle>
      <Table
        rows={results}
        headers={getColumns}
        handleRowClick={handleTableRowClick}
        renderTableCell={renderTableCell}
        ariaLabel="Tabela de gestão de provedores"
        isLoading={providersIsLoading}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={size}
        handleChangeRowsPerPage={handleRowsPerPage}
        total={count}
        filter={filter}
        handleApplyFilter={handleApplyFilter}
        handleFilterOption={handleFilterOption}
        handleSearch={handleSearchFilter}
        loadedPages={loadedPages}
      />
    </Grid>
  )
}

export default ManageProviders
